
import { Component, Prop, Vue ,Watch} from "vue-property-decorator";
import SimpleModal from '@/components/Modal/Util/SimpleModal.vue';
import * as DI from "@/utility/DependencyInjector";
// import User from 'fdtigermaster-client-sdk/lib/src/User/User';
@Component({
  components: {SimpleModal},
})
export default class ClockInPage extends Vue {
  @Prop() private item3!: string;

  //data
  btnDisable = false;
  hour:String = '00';
  mins:String = '00';
  sec:String = '00';
  isPunching = false;
  click = false;
  active:String= '上班';
  activeText = '';
  type:number = 0;
  time = '';
  date = '';
  user = DI.get('App').auth.currentUser.data.name;
  id = DI.get('App').auth.currentUser.id 
  //hook
  created(): void {
    if(!DI.get('App').auth.currentUser){
      alert('請先登入')
      this.$router.push({
        path: '/ClockIn/Login'
      })
    }
    this.updateClock()
  }

  //methods
  async punch (): Promise<void> {
    
    if(this.active  === '補上班'||this.active  === '補下班'){
       this.$bvModal.show("clock-in-modal");
       return
    }
      this.activeText = '打卡中...';
      this.isPunching = true;
      let method = this.active
      let time = `${this.hour}:${this.mins}:${this.sec}`
      const appInstance = DI.get('App');
      this.date = this.getCurrentDate();
      try{
        let checkRecords= await DI.get('App').clockin.list({
          userId: this.id,
          dateStart: this.date
          
        })
        checkRecords = checkRecords.data[0]
        if (checkRecords) {
          if (
            (this.active === '上班' && checkRecords.checkIn) ||
            (this.active === '下班' && checkRecords.checkOut) 
          ) {
            alert(`今天已經打過${this.active}卡 \n 上班時間 ${checkRecords.checkIn?checkRecords.checkIn:'無'} \n 下班時間 ${checkRecords.checkOut?checkRecords.checkOut:'無'}` );
            this.isPunching = false;
            return;
          }
       }
      await DI.get('App').clockin.create({
        time: time,
        date: this.date,
        type: this.type,
      })
      this.click = !this.click;
      this.click = true;
      this.isPunching = false;
      alert(`${method}, 打卡時間 ${this.date}+ ${time}`)
      }catch(e){
        this.isPunching = false;
        alert('打卡失敗')
        console.log(e)
      }finally{
        this.activeText = '';
      }
    }
  updateClock() {
    setInterval(() => {
      this.getTime();
    }, 1000);
  }

  getTime() {
    let date = new Date();
    let hour = date.getHours();
    let min = date.getMinutes();
    let sec = date.getSeconds();
    // Set Display
    this.hour = (hour < 10) ? '0' + hour : String(hour);
    this.mins = (min < 10) ? '0' + min : String(min);
    this.sec = (sec < 10) ? '0' + sec : String(sec);
  }
  logout(): void {
      DI.get("App").auth.logout();
      localStorage.removeItem("userState");
      this.$store.commit("LoginState", false);
      this.$router.push({
        path: "/ClockIn/login",
      });
    }
  
  cardActive (card:string,type:number) {
        this.active = card
        this.type = type
        if(card === '補上班'||card === '補下班'){
          this.time = '';
        this.date = '';
          this.$bvModal.show("clock-in-modal");
          
        
        }
      }
      
  async ModalClockIn(): Promise<void>{
    this.btnDisable = true;
  if(!(this.time&&this.date)){
    alert('請輸入日期與時間')
    this.btnDisable = false
    return
  }
  let Today=this.getCurrentDate()
   if(this.date > Today){
     alert('請輸入正確日期及時間');
     return;
   }
   try{
       this.activeText = '打卡中...';
       let checkRecords= await DI.get('App').clockin.list({
          userId: this.id,
          dateStart: this.date,
          dateEnd: this.date
          
        })
        checkRecords = checkRecords.data[0]
        if (checkRecords) {
          if (
              (this.active === '補上班' && (checkRecords.checkIn || checkRecords.fixCheckIn)) ||
              (this.active === '補下班' && (checkRecords.checkOut || checkRecords.fixCheckOut))
            ) {
     
            alert(`${this.date}已經打過上下班卡 \n 上班時間 ${checkRecords.checkIn ? checkRecords.checkIn : '無'} \n 下班時間 ${checkRecords.checkOut ? checkRecords.checkOut : '無'} \n 補上班時間 ${checkRecords.fixCheckIn ? checkRecords.fixCheckIn : '無'} \n 補下班時間 ${checkRecords.fixCheckOut ? checkRecords.fixCheckOut : '無'}`);

            this.isPunching = false;
            this.activeText = '';
            return;
          }
       }
      await DI.get('App').clockin.create({
        time: this.time,
        date: this.date,
        type: this.type,
      })
      this.click = !this.click
      alert(`成功打${this.active}卡 \n 日期:${this.date} \n 時間:${this.time}`)
      }catch(e){
        alert('打卡失敗')
        console.log(e)
      }finally{
        this.btnDisable = false
        this.activeText = '';
      }
    
    this.$bvModal.hide("clock-in-modal");
    
  }
    clockIn(){
      this.$bvModal.hide("clock-in-modal");
    }
  closeModal(name:string):void{
            this.$bvModal.hide(name);
        }
  getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}

  //computed
  get nowDate(): string {
    let Today=new Date();
    let dayInfo=`${Today.getFullYear()}年 ${Today.getMonth()+1}月${Today.getDate()} 日`
  return dayInfo
  }
  
  get ledColor () {
        switch (this.active) {
          case '上班':
            return 'led-punch-1'
          case '下班':
            return 'led-punch-2'
          case '補上班':
            return 'led-trip-1'
          case '補下班':
            return 'led-trip-2'
          default:
            break
        }
      }
   // watch
  @Watch('click')
  private isClicklWatch(){
      if (this.click) {
      setTimeout(() => { this.click = false }, 2000)    }
  }
}

